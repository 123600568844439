const animationDuration = 2000
const frameDuration = 1000 / 60
const totalFrames = Math.round( animationDuration / frameDuration )
const easeOutQuad = t => t * ( 2 - t )
export const countUpNumber = (el: Element) => {
    if (el.hasAttribute('data-counted-up')) return

    el.setAttribute('style', `width: ${ el.offsetWidth }px`)
    el.setAttribute('data-counted-up', '')

    let frame = 0
    const countTo = parseInt( el.textContent.replace(/\s+/g, ''), 10 )

    const counter = setInterval( () => {
        frame++

        const progress = easeOutQuad( frame / totalFrames )
        const currentCount = Math.round( countTo * progress )

        if ( parseInt( el.innerHTML.replace(/\s+/g, ''), 10 ) !== currentCount )
            el.innerHTML = currentCount.toLocaleString()

        frame === totalFrames && clearInterval( counter )
    }, frameDuration )
}